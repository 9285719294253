import React, { ReactElement } from "react";

type LearningValueProps = {
  readonly title: string;
  readonly image: string;
  readonly children: string;
};

export default function LearningValue({
  title,
  image,
  children,
}: LearningValueProps): ReactElement<LearningValueProps> {
  return (
    <div className="col-xs-12 col-sm-6 col-lg-4">
      <div className="icon-circle-block text-center">
        <img className="circle-icon" src={image} alt={`${title} icon`} />
        <h4 className="title">{title}</h4>
        <p>{children}</p>
      </div>
    </div>
  );
}
