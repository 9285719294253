import React, { ReactElement } from "react";
import {
  GatsbyMarkdownFile,
  FluidSharpImageFile,
} from "@ymcansw-camping/common";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import SchoolsLayout from "../../components/schools-layout";
import LearningValue from "../../components/learning-value";

type Data = {
  readonly pageFile: GatsbyMarkdownFile<{
    readonly values: ReadonlyArray<{
      readonly title: string;
      readonly summary: string;
      readonly icon: Readonly<{
        readonly publicURL: string;
      }>;
    }>;
    readonly outcomes: ReadonlyArray<{
      readonly title: string;
      readonly image: FluidSharpImageFile;
      readonly learningOutcomes: ReadonlyArray<{
        readonly title: string;
        readonly description: string;
      }>;
    }>;
  }>;
};

export default function LearningFrameworkPage(): ReactElement {
  const {
    pageFile: {
      childMarkdownRemark: { frontmatter },
    },
  } = useStaticQuery<Data>(graphql`
    {
      bannerHeaderImage: file(
        relativePath: { eq: "page-banner/community-bg.jpg" }
      ) {
        ...GeneralPageLayout_bannerHeaderImage
      }
      pageFile: file(relativePath: { eq: "pages/learning-framework.md" }) {
        childMarkdownRemark {
          rawMarkdownBody
          frontmatter {
            values {
              title
              summary
              icon {
                publicURL
              }
            }
            outcomes {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 788) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              learningOutcomes {
                title
                description
              }
            }
          }
        }
      }
    }
  `);

  return (
    <SchoolsLayout
      meta={{
        title: "Values",
        description:
          "Our Outdoor Education & Camping values form the foundation of our Outdoor Education & Camping community. Participants are introduced to the values at the beginning of a program and they guide the way we interact with each other during our time together on program.",
      }}
    >
      <div className="content-block">
        <h2>Values</h2>
        <p>
          Our Outdoor Education & Camping values form the foundation of our
          Outdoor Education & Camping community. Participants are introduced to
          the values at the beginning of a program and they guide the way we
          interact with each other during our time together on program.
        </p>
      </div>

      <div className="content-block icon-circle-container multi-level">
        <div className="row">
          {frontmatter.values.map((value) => (
            <LearningValue
              key={value.title}
              title={value.title}
              image={value.icon.publicURL}
            >
              {value.summary}
            </LearningValue>
          ))}
        </div>
      </div>

      {frontmatter.outcomes.map((outcome) => (
        <div
          key={outcome.title}
          className="content-block learning-framework-block"
        >
          <div className="learning-framework-banner pg-banner">
            <Img
              fluid={outcome.image.childImageSharp.fluid}
              alt={outcome.title}
            />
          </div>
          <div className="table-header">
            <p className="title">{outcome.title}</p>
          </div>

          <table className="table table-bordered learning-framework-table">
            <thead>
              <tr>
                <th>
                  <strong>Learning Outcomes</strong>
                </th>
                <th>
                  <strong>Descriptions</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {outcome.learningOutcomes.map((learningOutcome) => (
                <tr key={learningOutcome.title}>
                  <td>{learningOutcome.title}</td>
                  <td>{learningOutcome.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </SchoolsLayout>
  );
}
